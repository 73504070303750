import styles from "./findingsPanel.module.css";
import React, { useRef, useEffect } from "react";
import { Drawer } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from '@material-ui/icons/Visibility';
import FindingAccordion from "./findingAccordion/findingAccordion";
import { useStoreActions, useStoreState } from "easy-peasy";
import Tooltip from "@material-ui/core/Tooltip";
import PollIcon from '@material-ui/icons/Poll';
import scrollIntoView from 'scroll-into-view-if-needed';

export default ({ findings, selectedFinding }) => {
  const setCurrentSorting = useStoreActions(
    (actions) => actions.AnalysisRuns.setCurrentSorting
  );
  const sortFindings = useStoreActions(
    (actions) => actions.AnalysisRuns.sortFindings
  );
  const findingsPanelVisible = useStoreState(
    (state) => state.AnalysisRuns.findingsPanelVisible
  );
  const toggleFindingsPanel = useStoreActions(
    (actions) => actions.AnalysisRuns.toggleFindingsPanel
  );

  const findingRef = useRef(null);

  const isSelected = (finding) => (
    selectedFinding && selectedFinding.finding_id === finding.finding_id
  )

  useEffect(() => {
    findingRef && findingRef.current && scrollIntoView(findingRef.current, { behavior: 'smooth', scrollMode: 'if-needed' })
  }, [selectedFinding])

  sortFindings();

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={findingsPanelVisible}
      classes={{ paper: styles.paper }}
    >
      <div className={styles.drawerHeader}>
        {/* Sort by Time */}
        <Tooltip title="Sort by review state" arrow>
          <IconButton
            onClick={() => {
              setCurrentSorting("reviewState");
              sortFindings();
            }}
            className={`${styles.sortByButton} ${styles.panelButton}`}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Sort by confidence" arrow>
          <IconButton
            onClick={() => {
              setCurrentSorting("confidence");
              sortFindings();
            }}
            className={`${styles.sortByButton} ${styles.panelButton}`}
          >
            <PollIcon />
          </IconButton>
        </Tooltip>

        <IconButton
          onClick={() =>
            toggleFindingsPanel(false)
          }
          className={`${styles.closeButton} ${styles.panelButton}`}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className={styles.findings} style={{ paddingBottom: '63px'}}>
        {findings.map((finding) => (
          <div key={finding.finding_id} ref={isSelected(finding) ? findingRef : null}>
            <FindingAccordion
              finding={finding}
              highlighted={isSelected(finding)}
              />
          </div>
        ))}
      </div>
    </Drawer>
  );
};
